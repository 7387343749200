import React, { useMemo, useState } from 'react';

import { IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { useContextMenu } from '../ContextMenuContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useSelectedItems } from 'Drive/selectedItemContext';
import { useMinimalAuth } from 'hooks';
import useMenuMapper from './useMenuMapper';

import DriveMenuDialog from './Components/DriveMenuDialog';

import { isValidMap } from 'ReusableComponents';

import ApiManager from '../../ApiManager';

//keep this element as being the button
const DriveMenuButton = ({ skeleton = false, path, type, ...props }) => {
  const { setMenuOptions } = useContextMenu();
  const handleClick = (e) => {
    setMenuOptions((oldOptions) => {
      if (e.currentTarget && e.currentTarget !== oldOptions?.anchorEl) {
        return {
          path: path,
          type: type,
          anchor: { anchorEl: e.currentTarget },
          open: true,
        };
      } else {
        return {
          open: false,
        };
      }
    });
  };

  return (
    <IconButton onClick={handleClick} disabled={!!skeleton} {...props}>
      <MoreVert fontSize={'inherit'} />
    </IconButton>
  );
};

const useDriveRightClick = ({ path, type }) => {
  const { setMenuOptions } = useContextMenu();
  const { resetSelectedItems } = useSelectedItems();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'background') {
      resetSelectedItems();
    }

    setMenuOptions((oldMenuOptions) => {
      return {
        open: true,
        path: type === 'multiple' ? null : path,
        type,
        anchor: {
          anchorReference: 'anchorPosition',
          anchorPosition: {
            top: e.clientY - 4,
            left: e.clientX - 2,
          },
        },
      };
    });
  };

  return handleClick;
};

const WrapperForDriveContextMenu = ({ children }) => {
  const driveRightClick = useDriveRightClick({ type: 'background' });
  const layerClick = useDriveRightClick({ type: 'forceLayer' });

  return (
    <React.Fragment>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { onContextMenu: driveRightClick, layerClick: layerClick })
      )}
    </React.Fragment>
  );
};

const DriveMenu = () => {
  const { menuOptions, setMenuOptions } = useContextMenu();
  const { path, type } = menuOptions;

  const { breadcrumb, currentFolderInfo, mainMode } = useCurrentFolder();
  const { selectedItems } = useSelectedItems();

  const user = useMinimalAuth();

  const [dialog, setDialog] = useState(null);

  if (type === 'single' && !path) {
    throw new Error('A drive context menu of type single must have a path defined');
  }

  if (type === 'multiple' && !!path) {
    throw new Error('A drive context menu of type multiple cannot have a path defined');
  }

  if (type && !['single', 'multiple', 'background', 'forceLayer'].includes(type)) {
    throw new Error('type must be one of single, multiple or background, received ' + type);
  }

  const onClose = (e) => {
    if (!!e) {
      e.preventDefault();
    }

    setMenuOptions({
      ...menuOptions,
      open: false,
    });
  };

  const closeDialog = () => {
    setDialog(null);
  };

  const mapMenu = useMenuMapper({
    onClose,
    setDialog,
    closeDialog,
  });

  const preparedMenuOptions = useMemo(() => {
    const isLayer = path && (path.type === 'map' || path.type === 'shape' || path.type === 'pointCloud');
    const newLayer = ['newProject', 'newFolder', 'newFile', 'divider', 'newMore'];

    let menuOptions = [];

    if (type === 'forceLayer') {
      menuOptions = newLayer;
    } else if (type === 'background') {
      if (currentFolderInfo && currentFolderInfo.type === 'file') {
        menuOptions = ['download'];
      } else if (currentFolderInfo && currentFolderInfo.type !== 'folder') {
        //menuOptions = ['newTimestamp'];
      } else {
        menuOptions = newLayer;
      }
    } else if (type === 'multiple') {
      const hasDeleted = selectedItems.find((item) => item.deleted);
      const hasNonDeleted = selectedItems.find((item) => !item.deleted);
      const hasNoAccess = selectedItems.find(
        (item) => item.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
      );

      const hasDownloadAccess = selectedItems.find(
        (item) => item.yourAccess.accessLevel >= ApiManager.newAccessLevel['view+'] || item.type === 'file'
      );

      const hasBoth = hasDeleted && hasNonDeleted;
      menuOptions = [
        hasNonDeleted && !hasBoth && user && hasDownloadAccess && 'download',
        !hasNoAccess && !hasDeleted && 'moveTo',
        !hasBoth && !hasNoAccess && 'delete',
        !hasNonDeleted && 'permanentlyDelete',
      ];
    } else {
      let viewMenu = [];

      if (!path?.deleted) {
        const canView = path && path.type !== 'file' && path.type !== 'folder' && isValidMap(path).available;

        const canIntegrate = true;
        const canOpen = path?.type !== 'bookmark' && path?.id !== currentFolderInfo?.id;
        const canSharableLink = true;
        const canDownload =
          path?.type !== 'bookmark' &&
          (path?.type === 'file' ||
            (path && user && path.yourAccess.accessLevel >= ApiManager.newAccessLevel['view+']));

        viewMenu = [
          'viewHeading',
          canView && 'view',
          canOpen && 'open',
          canSharableLink && 'sharableLink',
          canIntegrate && 'integrate',
          canDownload && 'download',
          (mainMode === '/drive/search' || mainMode === '/drive/favorites') && 'openLocation',
        ];
      }
      //TODO canMove is depending on the accessLevel to the current folder, but that is problematic as we would like to have the info within the path. The backend will need to implement inheritedYourAccess for this
      const isBreadcrumb = path?.id === currentFolderInfo?.id;
      const canFavorite = !!user && path && (!path?.deleted || path?.favorite);
      const canRename = !path?.deleted && path?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];
      const canStorage = !path?.deleted;
      const canDescription = !!path;
      const canMove = isBreadcrumb
        ? mainMode === '/drive/me' ||
          (breadcrumb.path.length > 1 &&
            breadcrumb.path[breadcrumb.path.length - 1]?.info.yourAccess.accessLevel >=
              ApiManager.newAccessLevel['edit+'])
        : (mainMode === '/drive/me' && !currentFolderInfo) ||
          (currentFolderInfo?.type === 'folder' &&
            currentFolderInfo?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+']);

      let organizeMenu = [
        'divider',
        'organizeHeading',
        canFavorite && 'favorite',
        canRename && 'rename',
        canDescription && 'description',
        canMove && 'moveTo',
      ];

      if (!canFavorite && !canMove && !canDescription && !canRename) {
        organizeMenu = [];
      }

      const canSubscription =
        path?.type !== 'bookmark' &&
        path &&
        !path.deleted &&
        path.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];

      const canSettings =
        path &&
        isLayer &&
        !path.disabled &&
        !path?.deleted &&
        path.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];

      const canShare =
        !path?.deleted &&
        (path?.yourAccess.canShare === true || path?.yourAccess.accessLevel >= ApiManager.newAccessLevel['share']);

      let manageMenu = [
        'divider',
        'manageHeader',
        path?.type === 'process' && 'editProcess',
        canShare && 'manageSharing',
        canSubscription && 'manageSubscription',
        canStorage && 'storage',
        canSettings && 'mapSettings',
      ];

      if (!canSubscription && !canShare && !canSettings && !canStorage) {
        manageMenu = [];
      }

      const canDelete = path?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];
      const canPermanentlyDelete = path?.yourAccess.accessLevel >= ApiManager.newAccessLevel['admin'] && path?.deleted;
      const canLeave = mainMode === '/drive/shared' && !currentFolderInfo && !path?.yourAccess.userGroup;

      let extraMenu = [
        'divider',
        canDelete && 'delete',
        canPermanentlyDelete && 'permanentlyDelete',
        canLeave && 'leaveMap',
      ];

      if (!canDelete && !canLeave) {
        extraMenu = [];
      }

      menuOptions = [...viewMenu, ...organizeMenu, ...manageMenu, ...extraMenu];
    }

    return mapMenu(menuOptions);
  }, [type, mapMenu, currentFolderInfo, selectedItems, path, mainMode, breadcrumb.path, user]);

  return (
    <React.Fragment>
      {!!preparedMenuOptions && preparedMenuOptions?.length > 0 && (
        <Menu
          disablePortal
          keepMounted
          {...menuOptions.anchor}
          open={menuOptions.open}
          onClose={onClose}
          MenuListProps={{ dense: true }}
        >
          {preparedMenuOptions}
        </Menu>
      )}

      <DriveMenuDialog dialog={dialog} closeDialog={closeDialog} setDialog={setDialog} />
    </React.Fragment>
  );
};

export { DriveMenu, DriveMenuButton, useDriveRightClick, WrapperForDriveContextMenu };
