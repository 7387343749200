import React, { memo, useState, useEffect } from 'react';

import { Typography /* , Button */ } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
// import { Folder, Visibility } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { AvatarWithInfo } from '../AvatarWithInfo';
import { ProjectTypeIcon } from '../../ReusableComponents';
// import { useMinimalAuth } from 'hooks';
import { parseSize } from 'Storage/BalanceUtility';
// import ApiManager from 'ApiManager';

const useMapStyles = makeStyles({ name: 'SidebarTitle' })((theme) => ({
  root: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  mapName: {
    font: '1.5rem',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ownerText: {},
  button: {
    padding: 0,
    marginLeft: '10px',
  },
  icon: {
    height: '1.5rem',
  },
  blockSize: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  ownerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '0px',
  },
  viewerButton: {
    textTransform: 'none',
    width: '100%',
    marginTop: '8px',
    [theme.breakpoints.down('md')]: {
      width: '200px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  projectTypeIcon: {
    height: '30px',
    width: '30px',
    marginRight: '6px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

const SidebarTitle = ({ user, variant, base, storageSize, isFiles }) =>
  variant === 'account' ? (
    <SidebarTitleAccount user={user} />
  ) : (
    <SidebarTitleMap user={user} base={base} variant={variant} storageSize={storageSize} isFiles={isFiles} />
  );

SidebarTitle.propTypes = {
  variant: PropTypes.string,
};

const SidebarTitleMap = memo(({ user, variant, base, storageSize, isFiles }) => {
  // const authUser = useMinimalAuth();
  const { currentFolderInfo: map } = useCurrentFolder();
  // const { changeMainMode } = useCurrentFolder();
  // const history = useHistory();

  const [isMapViewable, setIsMapViewable] = useState(false);

  useEffect(() => {
    setIsMapViewable(map.timestamps.filter((x) => x.status === 'finished').length > 0);
  }, [map]);

  const { classes } = useMapStyles({ isMapViewable });

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <ProjectTypeIcon type={variant} base={base} className={classes.projectTypeIcon} />
        <Typography className={classes.mapName}>{map.name}</Typography>
      </div>
      <Typography className={classes.blockSize}>
        {storageSize ? (
          `Size: ${parseSize({ value: storageSize })}`
        ) : (
          <Skeleton variant="rectangular" width={100} height={16} />
        )}
      </Typography>
      <div className={classes.ownerContainer}>
        <Typography className={classes.ownerText} sx={{ mr: 1 }}>
          Owner:
        </Typography>
        <AvatarWithInfo user={user} size={20} />
        <Typography className={classes.ownerText}>{user.username}</Typography>
      </div>

      <div className={classes.buttonContainer}>
        {/* false && (
          <Button
            variant="contained"
            color="primary"
            endIcon={<Visibility />}
            className={`${classes.viewerButton} tutorialGoToViewer`}
            disabled={!isMapViewable}
            onClick={isMapViewable ? () => changeMainMode(`/view`) : null}
          >
            Open in Viewer
          </Button>
        ) */}
        {/* false && (
          <Button
            variant="contained"
            color="inherit"
            endIcon={<Folder />}
            className={`${classes.viewerButton} tutorialGoToViewer`}
            onClick={isMapViewable ? () => changeMainMode(`drive`) : null}
          >
            See files
          </Button>
        ) */}
      </div>
    </div>
  );
});

SidebarTitleMap.displayName = 'SidebarTitleMap';

SidebarTitleMap.propTypes = {
  user: PropTypes.object.isRequired,
  storageSize: PropTypes.number,
};

const useAccountStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  accountTextContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(1),
  },
  ownerAccountText: {
    overflowWrap: 'anywhere',
  },
  subtitle: {
    marginTop: '10px',
    color: theme.palette.text.secondary,
  },
}));

const SidebarTitleAccount = ({ user }) => {
  const { classes } = useAccountStyles();
  return (
    <div className={classes.root}>
      <div className={classes.accountTextContainer}>
        <AvatarWithInfo user={user} size={38} ButtonProps={{ sx: { m: -1 } }} />
        <Typography className={classes.ownerAccountText} variant="h3">
          {user.username}
        </Typography>
      </div>
    </div>
  );
};

SidebarTitleAccount.propTypes = {
  user: PropTypes.object.isRequired,
};

export default SidebarTitle;
