import React, { useState } from 'react';

import { Button } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import AccessLevelComponent from './AccessLevelComponent';
import AccessLevelMenu from './AccessLevelMenu';

const INIT_STATE_VALUE = {
  id: null,

  accessLevel: null,
  canShare: null,
  displayEdit: null,
  getMembers: null,
  max: null,
  min: null,
  minPublicAccessLevel: null,
  noNone: null,
  onChange: null,
  path: null,
  targetEl: null,
  targetUser: null,
};

const useStyles = makeStyles({ name: 'AccessLevelButton' })((theme) => ({
  root: {
    background: 'white',
    gap: theme.spacing(1.25),
    '& .MuiButton-endIcon': {
      marginLeft: 0,
      paddingLeft: theme.spacing(0.5),
      borderLeft: `1px ${theme.palette.divider} solid`,
    },
  },
}));

const AccessLevelButton = ({
  accessLevel,
  canShare,
  deleteUser,
  disabled,
  hasGeofence,
  displayEdit,
  max,
  min,
  noNone,
  onChange = () => {},
  path,
  skeleton,
  targetUser,
}) => {
  const { classes: styles } = useStyles();

  const [menuOptions, setMenuOptions] = useState(INIT_STATE_VALUE);

  const handleMenuSet = (props) => {
    if (typeof props === 'string' && props === 'reset') {
      setMenuOptions(INIT_STATE_VALUE);
    } else {
      setMenuOptions(props);
    }
  };

  const handleClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();

    handleMenuSet({
      accessLevel,
      canShare,
      deleteUser,
      displayEdit,
      max,
      min,
      noNone,
      onChange,
      path,
      targetEl: e.currentTarget,
      targetUser,
      hasGeofence,
    });
  };

  return (
    <>
      <Button
        className={styles.root}
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
        disabled={skeleton || disabled}
        fullWidth
        color="inherit"
      >
        <AccessLevelComponent accessLevel={accessLevel} skeleton={skeleton} disabled={disabled} isButton />
      </Button>
      <AccessLevelMenu setMenuOptions={handleMenuSet} menuOptions={menuOptions} />
    </>
  );
};

export default AccessLevelButton;
