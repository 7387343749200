// import { Clear, Map, PresentToAll } from '@mui/icons-material';

const PARAMETERS = [
  { type: 'heading', label: 'Essentials' },
  [
    {
      type: 'option',
      label: 'Logo',
      secondary: `Adds user avatar as logo on the top right of the map`,
      parameter: 'showLogo',
    },
    {
      type: 'option',
      label: 'Navbar',
      secondary: `Hide the navbar of the app`,
      parameter: 'hideNavbar',
    },
    { type: 'option', label: '3D', secondary: `Hide the option to switch to 3D`, parameter: 'hide3D' },
    {
      type: 'option',
      label: 'Your location',
      secondary: `Hide the option to fly to your location`,
      parameter: 'hideYourLocation',
    },
    {
      type: 'option',
      label: 'Viewer Controls',
      secondary: `Hide the controls in the bottom right corner of the map`,
      parameter: 'hideViewerControls',
    },
    {
      type: 'option',
      label: 'Use latest timestamp',
      secondary: `Display most recent timestamp for each layer`,
      parameter: 'useLatest',
    },
  ],
  { type: 'heading', label: 'Top Level Elements' },
  [
    {
      type: 'optionHeading',
      label: 'Side Pane',
      secondary: `To hide the viewer's Side Pane completely`,
      parameter: 'hideSidePane',
    },
    [
      {
        type: 'option',
        label: 'Browse',
        secondary: `Have the Side Pane but hide the Browse option`,
        parameter: 'hideBrowse',
      },
      {
        type: 'optionHeading',
        label: 'Detail Pane',
        secondary: `Hide the option to enter the layer details pane`,
        parameter: 'hideLayerMore',
      },
      { type: 'heading', label: 'Layer pane' },
      [
        {
          type: 'optionHeading',
          label: 'Layer Actions',
          secondary: `Hide the section titled as Layer Actions in the layer details pane`,
          parameter: 'hideLayerActions',
        },
        [
          {
            type: 'option',
            label: 'Center',
            secondary: `Hide the option to Center the layer`,
            parameter: 'hideLayerActionCenter',
          },
          {
            type: 'option',
            label: 'Changelog',
            secondary: `Hide the option Changelog under layer actions`,
            parameter: 'hideLayerActionChangelog',
          },
          {
            type: 'option',
            label: 'Feed',
            secondary: `Hide the option Feed under layer actions`,
            parameter: 'hideLayerActionFeed',
          },
          {
            type: 'option',
            label: 'Table',
            secondary: `Hide the option Table under layer actions`,
            parameter: 'hideLayerActionTable',
          },
          {
            type: 'option',
            label: 'Download',
            secondary: `Hide the option Download under layer actions`,
            parameter: 'hideLayerActionDownload',
          },
          {
            type: 'option',
            label: 'Files',
            secondary: `Hide the option to go to the Files page`,
            parameter: 'hideLayerActionToFiles',
          },
        ],
        {
          type: 'option',
          label: 'Layer Integrate',
          secondary: `Hide the section titled as Integrate in the layer details pane`,
          parameter: 'hideLayerIntegrate',
        },
        {
          type: 'optionHeading',
          label: 'Layer Buttons',
          secondary: `Hide the Buttons at the top of the layer details pane`,
          parameter: 'hideLayerButtons',
        },
        [
          {
            type: 'option',
            label: 'Add Feature',
            secondary: `Hide Add Feature under the Layer Actions`,
            parameter: 'hideLayerControlsAddFeature',
          },
          {
            type: 'option',
            label: 'Display Center Points Only',
            secondary: `Hide the option Display Center Points Only under the layer controls`,
            parameter: 'hideLayerControlsCenterPoints',
          },
          {
            type: 'option',
            label: 'Timestamps',
            secondary: `Hide the sub-section Timestamps under the layer actions`,
            parameter: 'hideLayerControlsTimestamps',
          },
        ],
        {
          type: 'optionHeading',
          label: 'Layer Controls',
          secondary: `Hide the section titled as Layer Controls in the layer details pane`,
          parameter: 'hideLayerControls',
        },
        [
          {
            type: 'option',
            label: 'Styling',
            secondary: `Hide the sub-section Styling under the layer controls`,
            parameter: 'hideLayerControlsStyling',
          },
          {
            type: 'option',
            label: 'Filters',
            secondary: `Hide the sub-section Filters under the layer controls`,
            parameter: 'hideLayerControlsFilters',
          },
          {
            type: 'option',
            label: 'Rendering',
            secondary: `Hide the sub-section Rendering under the layer controls`,
            parameter: 'hideLayerControlsTune',
          },
          {
            type: 'option',
            label: 'Legend',
            secondary: `Hide the Legend sub-section under the layer controls`,
            parameter: 'hideLayerControlsLegend',
          },
          {
            type: 'option',
            label: 'Analyse Raster',
            secondary: `Hide the sub-section Analyse Raster under the layer controls`,
            parameter: 'hideLayerControlsAnalyse',
          },
        ],
        { type: 'option', label: 'Layer Advanced', secondary: `Hide the Advanced section', parameter: 'hideAdvanced` },
      ],
      { type: 'heading', label: 'Feature pane' },
      [
        {
          type: 'option',
          label: 'Feature versions',
          secondary: `Hide the versions of the feature`,
          parameter: 'hideFeatureVersions',
        },
        {
          type: 'option',
          label: 'Time series',
          secondary: `Hide the time series of a feature`,
          parameter: 'hideFeatureSeries',
        },
        {
          type: 'option',
          label: 'Feature buttons',
          secondary: `Hide the buttons in the feature pane`,
          parameter: 'hideFeatureButtons',
        },
      ],
      {
        type: 'option',
        label: 'Create Draw Layer',
        secondary: `Hide the button Create Layer to Draw`,
        parameter: 'hideCreateDrawLayer',
      },
    ],
  ],
];

export default PARAMETERS;
