import React, { useState, useEffect } from 'react';

import { Button, Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth, useAuthFuncs } from '../../AuthContext';
import {
  SectionTitle,
  useStyles as useSectionStyles,
  useMainContext,
  ConfirmationDialog,
  InputDialog,
} from '../../../ReusableComponents';
import ApiManager from '../../../ApiManager';
import LinkSection from './LinkSection';
import { useInput } from '../../../hooks';

const DangerButton = ({ children, className = '', style: userStyles = {}, ...otherProps }) => {
  return (
    <Button variant="outlined" color="error" style={userStyles} {...otherProps}>
      {children}
    </Button>
  );
};

DangerButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

const useStyles = makeStyles()((theme) => ({
  emptyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  emptyTitle: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  emptySubTitle: {
    marginBottom: '16px',
  },
  newAppButton: {
    textTransform: 'none',
    height: '30px',
    minWidth: '212px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  redirectSection: {},
  listTitleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
    height: 'fit-content',
  },
  listButton: {
    minWidth: 'fit-content',
  },
  listContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
  listItem: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  listAction: {
    padding: 0,
  },
  secretButton: {
    marginTop: '10px',
  },
}));

const Developers = ({ path }) => {
  const { classes } = useStyles();
  const sectionClasses = useSectionStyles();
  const history = useHistory();
  const user = useAuth();
  const { fetchUserInfo } = useAuthFuncs();
  const { onOpenSnackbar } = useMainContext();

  const [newSecret, setNewSecret] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [newURLDialogOpen, setNewURLDialogOpen] = useState(false);
  const [newURL, handleNewURLChange] = useInput();

  useEffect(() => {
    if (history.location.state?.newClient && history.location.state?.clientSecret) {
      setNewSecret(history.location.state.clientSecret);
    }
  }, [history.location.state]);

  const handleAddRedirectURL = async () => {
    try {
      const res = await ApiManager.post(
        '/v3/oauth/editClient',
        { newRedirectUri: [...user.client.redirectUri, newURL], password: history.location.state.password },
        user
      );
      if (!!res) {
        fetchUserInfo();
        setNewURLDialogOpen(false);
        onOpenSnackbar({
          level: 'success',
          content: 'Redirect URL successfully added.',
        });
      }
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message,
      });
    }
  };

  const handleRemoveRedirectURL = async (URL) => {
    try {
      const res = await ApiManager.post(
        '/v3/oauth/editClient',
        {
          newRedirectUri: user.client.redirectUri.filter((url) => url !== URL),
          password: history.location.state.password,
        },
        user
      );
      if (!!res) {
        fetchUserInfo();
        onOpenSnackbar({
          level: 'success',
          content: 'Redirect URL successfully removed.',
        });
      }
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message,
      });
    }
  };

  const handleCreateSecret = async () => {
    try {
      const res = await ApiManager.post('/v3/oauth/newSecret', { password: history.location.state.password }, user);
      if (res?.clientSecret) {
        setNewSecret(res.clientSecret);
      }
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message,
      });
    }
  };

  const handleRemoveClient = async () => {
    try {
      const res = await ApiManager.post('/v3/oauth/deleteClient', { password: history.location.state.password }, user);
      await fetchUserInfo();
      if (!!res) {
        setRemoveDialogOpen(false);
        onOpenSnackbar({
          level: 'success',
          content: 'Client successfully removed',
        });
      }
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message,
      });
    }
  };

  if (user.openId && !user.openId.configuredPassword) {
    return (
      <div>
        <SectionTitle first={true}>
          <SectionTitle.SectionTitleText>OAuth Apps</SectionTitle.SectionTitleText>
        </SectionTitle>
        <div className={classes.emptyContainer}>
          <Typography className={classes.emptyTitle}>No OAuth applications</Typography>
          <Typography className={classes.emptySubTitle}>
            To register an OAuth client you need to configure an Ellipsis Drive password first.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.newAppButton}
            onClick={() => {
              const body = { email: user.email };
              ApiManager.post('/v3/account/security/password/reset', body).then((r) => {
                history.push(`/notification?type=setPasswordRequest&userId=${user.id}`);
              });
            }}
          >
            Set up Ellipsis Drive password
          </Button>
        </div>
      </div>
    );
  }

  if (!history.location.state?.password) {
    history.push('/login?userId=' + user.id, { from: path, update: 'developer settings' });
  }

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>OAuth Apps</SectionTitle.SectionTitleText>
      </SectionTitle>
      {!user.client?.clientId ? (
        <div className={classes.emptyContainer}>
          <Typography className={classes.emptyTitle}>No OAuth applications</Typography>
          <Typography className={classes.emptySubTitle}>
            OAuth applications are used to access the Ellipsis Drive API.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.newAppButton}
            onClick={() =>
              history.push('/account-settings/developers/new-oauth-app', { password: history.location.state?.password })
            }
          >
            Register a new application
          </Button>
        </div>
      ) : (
        <div className={classes.container}>
          <LinkSection titleText={'Your client ID'} link={user.client.clientId} />
          <section className={classes.redirectSection}>
            <div className={classes.listTitleContainer}>
              <Typography className={sectionClasses.groupTitle} variant="subtitle1">
                Secret
              </Typography>
              <Button className={classes.listButton} onClick={handleCreateSecret} variant="outlined" color="primary">
                Create a new secret
              </Button>
            </div>
            {newSecret && <LinkSection disableTitle={true} link={newSecret} />}
          </section>
          <section className={classes.redirectSection}>
            <div className={classes.listTitleContainer}>
              <Typography className={sectionClasses.groupTitle} variant="subtitle1">
                Your redirect URLs
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                className={classes.listButton}
                onClick={() => setNewURLDialogOpen(true)}
                disabled={user.client?.redirectUri?.length >= 3}
              >
                Add redirect URL
              </Button>
            </div>
            <div className={classes.listContainer}>
              {user.client.redirectUri.map((url) => (
                <div className={classes.listItem} key={url}>
                  <Typography>{url}</Typography>
                  {user.client.redirectUri?.length > 1 && (
                    <IconButton
                      className={classes.listAction}
                      onClick={() => handleRemoveRedirectURL(url)}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </section>

          <section className={classes.redirectSection}>
            <Typography className={sectionClasses.groupTitle} variant="subtitle1" gutterBottom color="error">
              Danger zone
            </Typography>
            <DangerButton onClick={() => setRemoveDialogOpen(true)} className={classes.secretButton}>
              Remove client
            </DangerButton>
          </section>
        </div>
      )}
      <ConfirmationDialog
        open={removeDialogOpen}
        handleClose={() => setRemoveDialogOpen(false)}
        title="Are you sure you want to remove this OAuth client?"
        legend={`All services associated with this clientId will stop working once the client is removed. Please be certain.`}
        agreeText="Remove"
        onClick={handleRemoveClient}
      />
      <InputDialog
        open={newURLDialogOpen}
        handleClose={() => setNewURLDialogOpen(false)}
        value={newURL}
        handleValueChange={handleNewURLChange}
        title="New redirect URL"
        legend="This is a link you can ask Ellipsis Drive to redirect to when the OAuth flow is completed."
        onClick={handleAddRedirectURL}
        agreeText="Add"
      />
    </div>
  );
};

export default Developers;
