import React, { useState, useEffect, useCallback } from 'react';

import ApiManager from 'ApiManager';

import { AvatarWithInfo, SectionTitle, useMainContext, UserSelect } from '../../../ReusableComponents';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemAvatar,
  TextField,
  DialogContentText,
  Popover,
  MenuList,
  ListItemIcon,
  Typography,
  ListItemSecondaryAction,
  ListItemButton,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useMinimalAuth } from '../../AuthContext';

import { AccountBox, Add, Delete, Edit, Group, MoreVert } from '@mui/icons-material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import ProfilePictureDialog from '../Profile/ProfilePictureDialog';
import VersionUtility from 'VersionUtility';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 'min(95vw, 360px)',
    maxHeight: 'min(80vh, 700px)',
    overflowY: 'auto',
    boxSizing: 'border-box',
    zIndex: 2000,
    marginTop: theme.spacing(1),
  },
  popper: {},
}));

const UserGroups = () => {
  const { classes } = useStyles();

  const user = useMinimalAuth();

  const [groups, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [newPicture, setNewPicture] = useState(null);

  const { onOpenSnackbar } = useMainContext();

  const [openRename, setOpenRename] = useState(false);

  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openSureRemove, setOpenSureRemove] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  const [openAddUser, setOpenAddUser] = useState(false);

  const addGroup = useCallback(() => {
    ApiManager.post(`/v3/organization/group`, { username: groupName }, user)
      .then((r) => {
        onOpenSnackbar({ status: 'success', content: 'Group has been added' });
        setOpenAddGroup(false);
        setGroups([...groups, { username: groupName, id: r.id, type: 'userGroup' }]);
      })
      .catch((e) => {
        console.error(e);
        onOpenSnackbar({
          level: 'error',
          content: e.message,
        });
      });
  }, [groups, onOpenSnackbar, user, groupName]);

  const removeGroup = useCallback(
    (u) => {
      ApiManager.delete(`/v3/organization/group/${u.id}`, {}, user)
        .then((res) => {
          setGroups(groups.filter((x) => x.id !== u.id));
          onOpenSnackbar({ status: 'success', content: 'User group removed' });
          setOpenSureRemove(false);
        })
        .catch((e) => {
          console.error(e);
          onOpenSnackbar({ level: 'error', content: e.message });
        });
    },
    [groups, onOpenSnackbar, user]
  );

  const submitNewName = (organization) => {
    ApiManager.put(`/v3/organization/group/${organization.id}/username`, { username: groupName }, user)
      .then((r) => {
        setOpenRename(false);
        setGroups((prev) => {
          return prev.map((o) => {
            if (organization.id === o.id) {
              const newO = { ...o };
              newO.username = groupName;
              return newO;
            } else {
              return o;
            }
          });
        });
      })
      .catch((err) => {
        console.error(err);
        onOpenSnackbar({
          level: 'error',
          content: 'Error fetching user groups',
        });
      });
  };

  useEffect(() => {
    ApiManager.get(`/v3/organization/${user.id}/group`, {}, user)
      .then((r) => {
        r = r.map((a) => VersionUtility.convertAccount(a));
        setGroups(r);
      })
      .catch((err) => {
        console.error(err);
        onOpenSnackbar({
          level: 'error',
          content: 'Error fetching user groups',
        });
      });
  }, [onOpenSnackbar, user]);

  const handlePictureSubmit = async (picture) => {
    ApiManager.patch(`/v3/organization/group/${openProfile.id}/profile`, { picture }, user)
      .then((r) => {
        setNewPicture(null);
        setOpenProfile(false);
        setGroups((prev) =>
          prev.map((g) => {
            if (g.id === openProfile.id) {
              return { ...g, picture };
            } else {
              return g;
            }
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Popover
        open={!!openPopOver}
        anchorEl={openPopOver?.anchor}
        role={undefined}
        transition
        onClose={() => {
          setOpenPopOver(false);
        }}
        className={classes.popper}
        BackdropProps={{ invisible: false }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList autoFocusItem={openPopOver}>
          <ListItemButton
            onClick={() => {
              setOpenPopOver(false);
              setOpenAddUser(openPopOver?.organization);
            }}
          >
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary={'Manage users'} />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              setOpenPopOver(false);
              setGroupName(openPopOver?.organization.username);
              setOpenRename(openPopOver?.organization);
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary={'Rename group'} />
          </ListItemButton>

          <ListItemButton
            onClick={() => {
              setOpenPopOver(false);
              setOpenProfile(openPopOver?.organization);
            }}
          >
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
            <ListItemText primary={'Update group image'} />
          </ListItemButton>

          <Divider sx={{ my: 0.5 }} />

          <ListItemButton
            onClick={() => {
              setOpenPopOver(false);

              setOpenSureRemove(openPopOver?.organization);
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary={'Remove'} />
          </ListItemButton>
        </MenuList>
      </Popover>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Organization groups</SectionTitle.SectionTitleText>
      </SectionTitle>
      {groups?.length > 0 && (
        <List sx={{ mb: 2 }}>
          {groups?.map((o) => (
            <ListItem key={o.id}>
              <ListItemAvatar>
                <AvatarWithInfo user={o} ButtonProps={{ sx: { m: -1 } }} />
              </ListItemAvatar>
              <ListItemText primary={o.username} />
              <span>
                <IconButton
                  onClick={(e) => {
                    setOpenPopOver({ anchor: e.currentTarget, organization: o });
                  }}
                >
                  <MoreVert />
                </IconButton>
              </span>
            </ListItem>
          ))}
        </List>
      )}
      <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => {
          setOpenAddGroup(true);
        }}
      >
        Add user group
      </Button>
      <Dialog open={!!openAddGroup} onClose={() => setOpenAddGroup(false)} fullWidth>
        <DialogTitle onClose={() => setOpenAddGroup(false)}>{'Adding a new user group'}</DialogTitle>
        <DialogContent>
          <TextField
            value={groupName}
            label={`User group name`}
            onChange={(e) => setGroupName(e?.target?.value)}
            fullWidth
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddGroup(false)} color="inherit">
            Cancel
          </Button>
          <Button disabled={!groupName} onClick={() => addGroup()} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <GroupDialog openAddUser={openAddUser} setOpenAddUser={setOpenAddUser} isOrg={true} />
      <Dialog open={!!openSureRemove} onClose={() => setOpenSureRemove(false)} fullWidth>
        <DialogTitle onClose={() => setOpenSureRemove(false)}>{'Remove user group'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to remove the user group? This will revoke all permissions of the underlying users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSureRemove(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              removeGroup(openSureRemove);
            }}
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!openRename} onClose={() => setOpenRename(false)} fullWidth>
        <DialogTitle onClose={() => setOpenRename(false)}>{'Rename user group'}</DialogTitle>
        <DialogContent>
          <TextField
            value={groupName}
            label={`New user group name`}
            onChange={(e) => setGroupName(e?.target?.value)}
            fullWidth
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRename(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              submitNewName(openRename);
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ProfilePictureDialog
        open={!!openProfile}
        handleClose={() => setOpenProfile(false)}
        setOpen={() => {}}
        newPicture={newPicture}
        setNewPicture={setNewPicture}
        onSubmit={handlePictureSubmit}
      />
    </div>
  );
};

export const GroupDialog = ({ openAddUser, setOpenAddUser, onDeleteSelf, isOrg }) => {
  const user = useMinimalAuth();

  const [selectedUser, setSelectedUser] = useState();
  const [groupUsers, setGroupUsers] = useState([]);
  const { onOpenSnackbar } = useMainContext();

  useEffect(() => {
    if (openAddUser) {
      ApiManager.get(`/v3/organization/group/${openAddUser.id}/user`, {}, user)
        .then((r) => {
          setGroupUsers(r);
        })
        .catch((e) => {
          console.error(e);
          onOpenSnackbar({
            level: 'error',
            content: e.message,
          });
        });
    } else {
      setGroupUsers([]);
    }
  }, [openAddUser, onOpenSnackbar, user]);

  const addUser = (organization, isAdmin) => {
    ApiManager.post(
      `/v3/organization/group/${organization.id}/user`,
      { userId: selectedUser.id, isGroupAdmin: isAdmin },
      user
    )
      .then((r) => {
        setGroupUsers((prev) => [...prev, selectedUser]);
        onOpenSnackbar({ status: 'success', content: 'User was added to the group' });

        setSelectedUser(null);
      })
      .catch((e) => {
        console.error(e);
        onOpenSnackbar({
          level: 'error',
          content: e.message,
        });
      });
  };

  const removeUserFromGroup = (u) => {
    ApiManager.delete(`/v3/organization/group/${openAddUser.id}/user/${u.id}`, {}, user)
      .then((r) => {
        setGroupUsers((prev) => prev.filter((x) => x.id !== u.id));

        if (u.id === user.id) {
          onDeleteSelf && onDeleteSelf();
          setOpenAddUser(false);
        }
      })
      .catch((err) => {
        console.error(err);
        onOpenSnackbar({
          level: 'error',
          content: 'Error fetching user groups',
        });
      });
  };

  return (
    <Dialog open={!!openAddUser} onClose={() => setOpenAddUser(false)} fullWidth>
      <DialogTitle onClose={() => setOpenAddUser(false)}>{'Group members'}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {(openAddUser.isGroupAdmin || isOrg) && (
            <Stack gap={1}>
              <Typography variant="subtitle1">Add user</Typography>
              <UserSelect value={selectedUser} user={user} emailSwitch={true} onChange={(u) => setSelectedUser(u)} />

              {selectedUser && (
                <Stack gap={1} flexDirection="row">
                  <Button onClick={() => addUser(openAddUser, false)} color="primary">
                    Add User to Group
                  </Button>
                  <Button onClick={() => addUser(openAddUser, true)} color="primary">
                    Add User as Group admin
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
          <Box>
            <Typography variant="subtitle1">Current members</Typography>

            <List disablePadding>
              {!groupUsers?.length ? (
                <ListItem>No members</ListItem>
              ) : (
                groupUsers.map((u) => (
                  <ListItem key={u.id}>
                    <ListItemIcon>
                      <AvatarWithInfo user={u} size={32} />
                    </ListItemIcon>
                    <ListItemText primary={u.username} secondary={u.isGroupAdmin ? 'group admin' : undefined} />
                    {(openAddUser.isGroupAdmin || u.id === user.id || isOrg) && (
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => removeUserFromGroup(u)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))
              )}
            </List>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UserGroups;
