import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { useMinimalAuth, useQueryParams } from 'hooks';
import ApiManager from 'ApiManager';
import VersionUtility from 'VersionUtility';
import isEqual from 'lodash/isEqual';

export const POLLING_RATE = 10000;

const RELEVENT_URLS = [
  '/drive/me',
  '/drive/shared',
  '/drive/favorites',
  '/drive/trash',
  '/drive/search',
  '/drive/external',
  '/view',
  '/settings',
  '/settings/general',
  '/settings/ogc',
  '/settings/sharing',
  '/settings/subscriptions',
  '/settings/styles',
  '/settings/bands',
  '/settings/usage',
  '/settings/change-location',
  '/settings/properties',
  '/404',
  '/thumbnail',
];

const REDIRECTS = ['/', '/drive', '/drive/projects'];

export let closeViewerFlow = 'files';

/////////////////////////TO DO: do not use setBreadcrumb, you should update the history, the useEffect will then set the breadcrumb
const getUrl = (root, id, type = 'folder') => {
  let url = getMainModeFromRoot(root);
  let paramName = 'pathId';
  if (id) {
    url = url + '?' + paramName + '=' + id;
  }
  return url;
};

const urlToRoot = (url, path) => {
  let root = url.split('/')[2];

  root = root === 'me' ? 'myMaps' : root;
  if (root === 'search' || root === 'trash') {
    return;
  }
  return root;
};

export const getMainModeFromRoot = (root) => {
  if (root === 'myMaps') {
    return '/drive/me';
  } else if (
    root === 'trash' ||
    root === 'search' ||
    root === 'shared' ||
    root === 'favorites' ||
    root === 'external'
  ) {
    return '/drive/' + root;
  } else {
    throw new Error('unknown root');
  }
};

export const pathToRoot = (path) => {
  if (path.path.root === 'myMaps') {
    return 'myMaps';
  } else if (path.path.root === 'sharedWithMe') {
    return 'shared';
  } else if (path.favoriteLocation) {
    return 'favorites';
  } else {
    return 'external';
  }
};

const canGoToRoute = (path, root) => {
  if (!path) {
    return true;
  } else if (root === 'myMaps' && path.path.root === 'myMaps') {
    return true;
  } else if (root === 'shared' && path.path.root === 'sharedWithMe') {
    return true;
  } else if (root === 'favorites' && !!path.path.favoriteLocation) {
    return true;
  } else if (root === 'external' && !path.favoriteLocation && path.path.root === 'external') {
    return true;
  } else {
    return false;
  }
};

const getPath = (path, root) => {
  let returnPath;
  if (root === 'favorites') {
    returnPath = [...path.favoriteLocation.path];
  } else if (root === 'shared' || root === 'myMaps' || root === 'external') {
    returnPath = [...path.path.path];
  } else {
    returnPath = [{ id: path.id, name: path.name, info: path }];
  }
  returnPath.reverse();

  returnPath[returnPath.length - 1] = { ...returnPath[returnPath.length - 1], info: path };

  return returnPath;
};

const currentFolderContext = createContext();

const CurrentFolderProvider = ({ children }) => {
  const user = useMinimalAuth();
  const history = useHistory();

  const usehistoryStateKeys = useRef({});
  const setHistoryStateKey = () => {
    const key = Math.random();
    usehistoryStateKeys.current[key] = true;
    return key;
  };

  const breadcrumb = useMemo(() => {
    const b = history.location.state?.breadcrumb
      ? history.location.state.breadcrumb
      : { mainMode: null, path: [], root: null, user: user };
    // console.log('history update', b);
    return b;
  }, [history.location.state?.breadcrumb, user]);

  const path = breadcrumb.path;
  const root = breadcrumb.root;
  const currentUrl = history.location.pathname + history.location.search;

  const { q: qSearch } = useQueryParams();

  const currentFolderInfo = useMemo(() => (path?.length > 0 ? path[path.length - 1] : null), [path]);
  const block = useRef(false);

  //functions to redirect with breadcrumb adaptation
  const changeMainMode = useCallback(
    (newMode) => {
      if (newMode === 'drive') {
        newMode = getMainModeFromRoot(breadcrumb.root);
      }

      let newBreadcrumb = { ...breadcrumb, mainMode: newMode, key: setHistoryStateKey() };
      let url = newMode + '?pathId=' + currentFolderInfo.id;

      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, currentFolderInfo, history]
  );

  const changeMainModeAndExit = useCallback(
    (newMode) => {
      newMode = getMainModeFromRoot(breadcrumb.root);
      let newPath = [...breadcrumb.path];
      newPath.pop();
      let newBreadcrumb = {
        ...breadcrumb,
        mainMode: newMode,
        goOneBack: false,
        path: newPath,
        key: setHistoryStateKey(),
      };
      let url = newMode;
      if (newPath.length > 0) {
        url = url + '?pathId=' + newPath[newPath.length - 1].id;
      } else if (breadcrumb.root === 'external') {
        history.push('/drive/me');
        return;
      }

      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, history]
  );

  useEffect(() => {
    if (breadcrumb.mainMode !== '/view') {
      closeViewerFlow = 'files';
    }
  }, [breadcrumb.mainMode]);

  const changeMainModeAndEnter = useCallback(
    (newPath, newMainMode) => {
      if (block.current) {
        return;
      }
      if (newMainMode === '/view') {
        closeViewerFlow = 'drive';
      }

      block.current = true;
      let url = newMainMode + '?pathId=' + newPath.id;
      //in case user opened to folders at the same time we need to unfack that here

      let newBreadcrumb = {
        ...breadcrumb,
        goOneBack: true,
        mainMode: newMainMode,
        path: [...breadcrumb.path, { id: newPath.id, name: newPath.name, info: newPath }],
        key: setHistoryStateKey(),
      };

      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, history]
  );

  const onBreadcrumbClick = useCallback(
    (id) => {
      let newBreadcrumb;
      if (!id) {
        newBreadcrumb = { ...breadcrumb, path: [] };
      } else {
        let newPath = [...breadcrumb.path];
        const index = newPath?.findIndex(({ id: pathId }) => pathId === id);

        newPath.splice(index + 1, path?.length - index - 1);
        newBreadcrumb = { ...breadcrumb, path: newPath, key: setHistoryStateKey() };
      }
      let url = getUrl(breadcrumb.root, id);
      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, history, path]
  );

  const onToLocationClick = useCallback(
    (newPath, mainMode = null, params = '') => {
      let newRoot = pathToRoot(newPath);
      let p = getPath(newPath, newRoot);

      let url;
      if (mainMode && mainMode !== '404') {
        url = `${mainMode}?pathId=${newPath.id}${params?.length > 0 ? '&' + params : params}`;
      } else {
        url = getUrl(newRoot, newPath.id, newPath.type);
        mainMode = getMainModeFromRoot(newRoot);
      }

      let newBreadcrumb = {
        ...breadcrumb,
        root: newRoot,
        error: null,
        path: p,
        mainMode: mainMode,
        searchValue: '',
        key: setHistoryStateKey(),
      };
      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, history]
  );
  const onFolderClick = useCallback(
    (p, url) => {
      if (block.current) {
        return;
      }
      block.current = true;

      let oldValues = breadcrumb.path;
      let newValues = [...oldValues, { id: p.id, name: p.name, info: p }];
      let newBreadcrumb = { ...breadcrumb, path: newValues, key: setHistoryStateKey() };

      if (url) {
        newBreadcrumb.mainMode = url;
      }

      url = url ? url + '?pathId=' + p.id : getUrl(breadcrumb.root, p.id, p.type);
      history.push(url, { breadcrumb: newBreadcrumb });
    },
    [breadcrumb, history]
  );

  const putInShared = useCallback(
    (res) => {
      let url = '/drive/shared?pathId=' + currentFolderInfo.id;

      history.replace(url, {
        breadcrumb: {
          ...breadcrumb,
          root: 'shared',
          mainMode: breadcrumb.mainMode.includes('/view') ? currentUrl : '/drive/shared',
          path: [{ id: res.id, name: res.name, info: res }],
          key: setHistoryStateKey(),
        },
      });
    },
    [breadcrumb, currentFolderInfo?.id, history, currentUrl]
  );

  const favoriteWholePathFolder = useCallback(() => {
    let newPath = breadcrumb.path.map((x, i) => {
      let driveLocation = path.slice(0, i + 1);
      let newInfo;
      if (i === 0) {
        newInfo = { ...x.info, favorite: true, favoriteLocation: { path: driveLocation } };
      } else {
        newInfo = { ...x.info, favoriteLocation: { path: driveLocation } };
      }
      return { ...x, info: newInfo };
    });

    const url =
      breadcrumb.mainMode === '/view' || breadcrumb.mainMode.includes('/settings')
        ? currentUrl
        : getUrl('favorites', currentFolderInfo.id);
    history.replace(url, {
      breadcrumb: {
        ...breadcrumb,
        root: 'favorites',
        path: newPath,
        mainMode: '/drive/favorites',
        key: setHistoryStateKey(),
      },
    });
  }, [history, breadcrumb, currentUrl, currentFolderInfo?.id, path]);

  const favoriteFolder = useCallback(
    (folder) => {
      let newFolder = { ...folder };
      newFolder.favorite = true;
      newFolder.favoriteLocation = { path: [{ id: folder.id, name: folder.name }] };
      const newBreadcrumb = {
        ...breadcrumb,
        root: 'favorites',
        path: [{ id: newFolder.id, name: newFolder.name, info: newFolder }],
        mainMode: breadcrumb.mainMode === '/view' ? '/view' : '/drive/favorites',
        key: setHistoryStateKey(),
      };
      if (breadcrumb.mainMode === '/view') {
        const url = history.location.pathname + history.location.search;
        history.replace(url, {
          ...history.location.state,
          ...{ breadcrumb: newBreadcrumb },
        });
      } else {
        history.replace(getUrl('favorites', newFolder.id), {
          ...history.location.state,
          ...{ breadcrumb: newBreadcrumb },
        });
      }
    },
    [history, breadcrumb]
  );

  const setSearchValue = useCallback(
    (searchValue) => {
      if (searchValue === '' || !searchValue || Object.keys(searchValue)?.length === 0) {
        let mainMode = getMainModeFromRoot(breadcrumb.root);
        let newBreadcrumb = { ...breadcrumb, searchValue: null, mainMode: mainMode, key: setHistoryStateKey() };
        history.replace(mainMode, { ...history.location.state, ...{ breadcrumb: newBreadcrumb } });
      } else {
        const preppedValue = encodeURIComponent(JSON.stringify(searchValue));
        const newBreadcrumb = { ...breadcrumb, searchValue: preppedValue, mainMode: '/drive/search' };

        history.replace('/drive/search?q=' + preppedValue, {
          ...history.location.state,
          ...{ breadcrumb: newBreadcrumb },
        });
      }
    },
    [breadcrumb, history]
  );

  const getSearchValue = useCallback(
    () => !!qSearch && !!decodeURIComponent(qSearch) && { ...JSON.parse(decodeURIComponent(qSearch)) },
    [qSearch]
  );

  //end

  //functions to update the breadcrumb
  const updatePath = useCallback(
    (id, obj) => {
      const newBreadcrumb = {
        ...breadcrumb,
        path: breadcrumb.path.map((path) =>
          path?.id === id
            ? {
                ...path,
                name: obj?.name ? obj.name : path?.name,
                info: { ...path.info, ...obj },
              }
            : path
        ),
        key: setHistoryStateKey(),
      };
      history.replace(currentUrl, { ...history.location.state, ...{ breadcrumb: newBreadcrumb } });
    },
    [breadcrumb, history, currentUrl]
  );

  //only use for updating 1
  const getPathInfoRaced = useRef({ busy: false, N: 0 });
  const getPathInfo = useCallback(
    async (givenPathId) => {
      if (!givenPathId) {
        givenPathId = currentFolderInfo?.id;
      }

      // console.log('getPathInfo');

      let res;
      const time = new Date();
      const r = Math.random();
      getPathInfoRaced.current = { busy: true, N: r };
      try {
        res = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${givenPathId}`, null, user));
      } catch (error) {
        console.error(error);
        return;
      }

      if (currentFolderInfo?.id === givenPathId && isEqual(currentFolderInfo?.info, res)) {
        return res;
      }
      //we should update the local storage here

      let updateElement = breadcrumb.path.find((b) => b.id === givenPathId);

      if (!updateElement || (!!updateElement?.time && new Date(updateElement.time) > time)) {
        return res;
      } else {
        let newPath = breadcrumb.path.map((p) => {
          if (p.id === givenPathId) {
            return { ...p, name: res.name, info: res, time: time };
          } else {
            return p;
          }
        });

        const newBreadcrumb = { ...breadcrumb, path: newPath, user: user, key: setHistoryStateKey() };
        console.log('RACED1');
        if (getPathInfoRaced.current.N === r) {
          console.log('RACED2');

          getPathInfoRaced.current = { busy: false, N: 0 };
          history.replace(currentUrl, {
            ...history.location.state,
            ...{ breadcrumb: newBreadcrumb },
          });
        }
        return res;
      }
    },
    [currentFolderInfo?.id, currentFolderInfo?.info, user, history, currentUrl, breadcrumb]
  );

  useEffect(() => {
    if (getPathInfoRaced.current.busy && false) {
      getPathInfo();
    } else {
      getPathInfoRaced.current = { busy: false, N: 0 };
    }
  }, [breadcrumb, getPathInfo]);

  //handle inviate and transfer accept
  const handleAccept = useCallback(async () => {
    const res = await getPathInfo();

    if ((root === 'external' && res.path.root !== 'external') || breadcrumb.mainMode === '404') {
      onToLocationClick(res);
      return;
    }

    let newPath = breadcrumb.path.map((p, i) => {
      if (i === breadcrumb.path.length - 1) {
        return { id: p.id, name: p.name, info: res };
      } else {
        return { name: p.name, id: p.id, info: null };
      }
    });

    history.replace(currentUrl, {
      ...history.location.state,
      breadcrumb: { ...breadcrumb, path: newPath, key: setHistoryStateKey() },
    });
  }, [history, currentUrl, getPathInfo, breadcrumb, onToLocationClick, root]);

  //end

  //initialization functions
  const onEnter = useCallback(
    (newPath, mainMode, u) => {
      if (mainMode.includes('/settings') && newPath.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']) {
        history.replace('/drive/external?pathId=' + newPath.id);
        return;
      }
      const canGo = canGoToRoute(newPath, urlToRoot(mainMode));
      if (!canGo) {
        let alternativeRoot = pathToRoot(newPath);
        let p = getPath(newPath, alternativeRoot);
        let newMainMode = mainMode;
        if (mainMode.includes('/drive')) {
          newMainMode = getMainModeFromRoot(alternativeRoot);
        }

        //correct the url if needed
        let url = currentUrl;
        if (mainMode.includes('/drive')) {
          url = newMainMode;
          url = url + '?pathId=' + newPath.id;
        }
        history.replace(url, {
          ...history.location.state,
          breadcrumb: { root: alternativeRoot, path: p, mainMode: newMainMode, user: u, key: setHistoryStateKey() },
        });
      } else {
        if (newPath) {
          let root = urlToRoot(mainMode);
          if (!root) {
            root = pathToRoot(newPath);
          }
          let p = getPath(newPath, root);
          history.replace(currentUrl, {
            ...history.location.state,
            breadcrumb: { root: root, path: p, mainMode: mainMode, user: u, key: setHistoryStateKey() },
          });
        } else {
          history.replace(currentUrl, {
            ...history.location.state,
            breadcrumb: { root: root, path: [], mainMode: mainMode, user: u, key: setHistoryStateKey() },
          });
        }
      }
    },
    //DO NOT FILL IN ROOT IT IS A LIE AND WILL CAUSE TROUBLE. SERIOUSLY DO NOT ADD IT
    [history, root, currentUrl]
  );

  const lastTime = useRef(new Date());
  const onSetInitialPath = useCallback(
    async (givenPathId, mainMode, u) => {
      let res;
      let time = new Date();
      if (!givenPathId) {
        if (mainMode === '/view' || mainMode.includes('/settings') || mainMode === '/drive/external') {
          history.replace('/404');
          return;
        } else {
          history.replace(currentUrl, {
            ...history.location.state,
            ...{
              breadcrumb: {
                root: urlToRoot(mainMode),
                path: [],
                mainMode: mainMode,
                user: u,
                key: setHistoryStateKey(),
              },
            },
          });
          return;
        }
      } else {
        try {
          res = await VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${givenPathId}`, null, u));
        } catch (error) {
          const newBreadcrumb = {
            root: null,
            path: [{ id: givenPathId }],
            mainMode: '404',
            error: { type: 'path', status: error.status },
            key: setHistoryStateKey(),
          };
          history.replace(currentUrl, { ...history.location.state, breadcrumb: newBreadcrumb });

          return;
        }
      }

      if (res.type === 'bookmark' && (mainMode.includes('/drive') || mainMode.includes('/settings'))) {
        const newBreadcrumb = {
          root: null,
          path: [],
          mainMode: '404',
          error: { type: res.type, status: '' },
          key: setHistoryStateKey(),
        };

        history.replace(currentUrl, { ...history.location.state, breadcrumb: newBreadcrumb });

        return;
      }

      if ((res.type === 'file' || res.type === 'folder') && (mainMode === '/view' || mainMode.includes('/settings'))) {
        const newBreadcrumb = {
          root: null,
          path: [],
          mainMode: '404',
          error: { type: res.type, status: '' },
          key: setHistoryStateKey(),
        };

        history.replace(currentUrl, { ...history.location.state, breadcrumb: newBreadcrumb });

        return;
      }
      if (time > lastTime.current) {
        lastTime.current = time;
        onEnter(res, mainMode, u);
      }
    },
    [onEnter, history, currentUrl]
  );

  //end

  //React to url changes and user change

  useEffect(() => {
    block.current = false;
  }, [breadcrumb.mainMode, currentFolderInfo?.id]);

  const oldUserId = useRef(user?.id);
  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);

    let pathId = urlParams.get('pathId');
    let mapId = urlParams.get('mapId');
    pathId = pathId ? pathId : mapId;

    urlParams.delete('pathId');
    urlParams.delete('mapId');

    if (REDIRECTS.includes(history.location.pathname)) {
      history.replace(`/drive/me${urlParams?.has('demoMode') ? `?demoMode=${urlParams?.get('demoMode')}` : ''}`, {
        breadcrumb: { mainMode: '/drive/me', path: [], root: 'myMaps', user: user, key: setHistoryStateKey() },
      });
      return;
    }

    if (!RELEVENT_URLS.includes(history.location.pathname)) {
      return;
    }

    if (
      history.location.state?.breadcrumb &&
      oldUserId.current === user?.id &&
      usehistoryStateKeys.current?.[history.location.state.breadcrumb.key]
    ) {
      // console.log('prevented refresh');
      usehistoryStateKeys.current[history.location.state.breadcrumb.key] = false;
      return;
    } else {
      // console.log('NOT prevented refresh');

      oldUserId.current = user?.id;

      if (history.location.pathname === '/drive/trash') {
        history.replace(currentUrl, {
          ...history.location.state,
          breadcrumb: {
            path: [],
            root: null,
            mainMode: history.location.pathname,
            user: user,
            key: setHistoryStateKey(),
          },
        });
      } else if (history.location.pathname === '/drive/search') {
        history.replace(currentUrl, {
          ...history.location.state,
          breadcrumb: {
            root: 'myMaps',
            path: [],
            mainMode: '/drive/search',
            searchValue: qSearch,
            user: user,
            key: setHistoryStateKey(),
          },
        });
      } else {
        onSetInitialPath(pathId, history.location.pathname, user);
      }
    }
  }, [
    history.location.pathname,
    history.location.search,
    user,
    breadcrumb,
    onSetInitialPath,
    history,
    currentUrl,
    qSearch,
  ]);

  /* //If we are missing the info object of the current (last) element in path, we get it's info
  if (currentFolderInfo && !currentFolderInfo.info) {
    getPathInfo(currentFolderInfo.id);
    throw new Error('currentFolderInfo of last element in path should always be defined');
  } */

  //poll function to keep the current map up to date
  const [pollUpdate, setPollUpdate] = useState(1);
  const pollRef = useRef(pollUpdate);

  const poll = useCallback(() => {
    setPollUpdate((u) => {
      pollRef.current = u + 1;
      return u + 1;
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      poll();
    }, POLLING_RATE);

    return () => clearInterval(interval);
  }, [poll]);

  const lastChecked = useRef(currentFolderInfo?.id);
  const lastCheckedUser = useRef(user?.id);

  const prevRun = useRef(null);

  useEffect(() => {
    if (prevRun.current === pollRef.current) return;
    prevRun.current = pollRef.current;

    if (
      !!currentFolderInfo?.id &&
      lastChecked.current === currentFolderInfo.id &&
      lastCheckedUser.current === user?.id &&
      breadcrumb.mainMode &&
      (breadcrumb.mainMode.includes('/drive') || breadcrumb.mainMode.includes('/settings')) &&
      currentFolderInfo?.info?.type !== 'folder'
    ) {
      getPathInfo();
    } else {
      lastChecked.current = currentFolderInfo?.id;
      lastCheckedUser.current = user?.id;
    }
  }, [pollUpdate, user, currentFolderInfo?.id, breadcrumb.mainMode, currentFolderInfo?.info?.type, getPathInfo]);

  useEffect(() => {
    if (breadcrumb.path?.map((p) => !p?.info && p?.id)?.filter((x) => !!x)?.length > 0) {
      Promise.all(breadcrumb?.path?.map((p) => !p?.info && ApiManager.get(`/v3/path/${p?.id}`, null, user)))
        .then((res) => {
          history.replace(currentUrl, {
            breadcrumb: {
              ...breadcrumb,
              path: breadcrumb.path?.map((p, i) => ({
                ...p,
                ...(!!res[i] ? { info: VersionUtility.convertPathInfo(res[i]) } : {}),
              })),
              user: user,
              key: setHistoryStateKey(),
            },
          });
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumb.path]);

  //end
  const value = useMemo(
    () => ({
      breadcrumb,
      breadcrumbError: breadcrumb.error,
      changeMainMode,
      changeMainModeAndEnter,
      changeMainModeAndExit,
      currentFolderInfo: currentFolderInfo?.info,
      currentPathId: currentFolderInfo?.id,
      favoriteFolder,
      favoriteWholePathFolder,
      getPathInfo,
      getSearchValue,
      goOneBack: breadcrumb.goOneBack,
      handleAccept,
      mainMode: breadcrumb.mainMode,
      onBreadcrumbClick,
      onFolderClick,
      onToLocationClick,
      path,
      poll,
      putInShared,
      root: root,
      searchValue: breadcrumb.searchValue,
      setSearchValue,
      update: currentFolderInfo?.update,
      updatePath,
      user: breadcrumb.user,
    }),
    [
      breadcrumb,
      changeMainMode,
      changeMainModeAndEnter,
      changeMainModeAndExit,
      currentFolderInfo?.id,
      currentFolderInfo?.info,
      currentFolderInfo?.update,
      favoriteFolder,
      favoriteWholePathFolder,
      getPathInfo,
      getSearchValue,
      handleAccept,
      onBreadcrumbClick,
      onFolderClick,
      onToLocationClick,
      path,
      poll,
      putInShared,
      root,
      setSearchValue,
      updatePath,
    ]
  );

  //end
  return <currentFolderContext.Provider value={value}>{children}</currentFolderContext.Provider>;
};

const useCurrentFolder = () => {
  const context = useContext(currentFolderContext);

  if (context === undefined) {
    throw new Error('useCurrentFolder must be used within a CurrentFolderProvider');
  }

  return context;
};

export function withBreadcrumb(Component) {
  return function WrapperComponent(props) {
    const context = useCurrentFolder();
    return <Component {...props} {...{ breadcrumb: context }} />;
  };
}

export { CurrentFolderProvider, useCurrentFolder };
