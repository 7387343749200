import React from 'react';

import { makeStyles, withStyles } from 'tss-react/mui';
import { Avatar, Badge } from '@mui/material';
import { Business, Group } from '@mui/icons-material';

import { useProfileContext } from 'ReusableComponents/ProfileContext';

import { getAvatarColor, getAvatarTextColor } from 'AppUtility';

const useStyles = makeStyles({ name: 'CustomAvatar' })((theme) => ({
  root: { display: 'inline-flex' },
}));

const CustomAvatar = ({ user, size = 40, className, sx = {}, style = {}, variant = 'circular', colorId, ...props }) => {
  const { classes: styles, cx } = useStyles();
  const profile = useProfileContext(user);

  // console.log(profile);

  if (Object.keys(props).length) {
    console.log({ ...props });
  }

  const backgroundColor = getAvatarColor({ id: colorId || user?.id });

  const text = user && (user?.username ?? user?.name ?? user?.email);

  const inner = (
    <Avatar
      className={cx(styles.root, className)}
      src={profile?.cachedImageUrl}
      variant={variant}
      sx={{
        backgroundColor,
        height: size,
        width: size,
        fontSize: size / 2,
        color: getAvatarTextColor(backgroundColor),
        ...style,
        ...sx,
      }}
      {...props}
    >
      {!!text ? text?.substr(0, 2).toUpperCase() : null}
    </Avatar>
  );

  return user?.type === 'organization' ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <SmallAvatar size={size}>
          <Business fontSize="inherit" sx={{ width: '66%', height: '66%' }} />
        </SmallAvatar>
      }
    >
      {inner}
    </Badge>
  ) : user?.type === 'userGroup' ? (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <SmallAvatar size={size}>
          <Group fontSize="inherit" sx={{ width: '66%', height: '66%' }} />
        </SmallAvatar>
      }
    >
      {inner}
    </Badge>
  ) : (
    inner
  );
};

export default CustomAvatar;

const getRatio = (size) => (size <= 64 ? 3 / 5 : 1 / 3);
const getSize = (size) =>
  typeof size === 'number' ? Math.round(size * getRatio(size)) : `calc(${size} * ${getRatio(size)})`;

const SmallAvatar = ({ size, ...props }) => (
  <Avatar
    sx={(theme) => ({
      height: getSize(size),
      width: getSize(size),
      background: theme.palette.action.active,
      boxShadow: theme.shadows[2],
    })}
    {...props}
  />
);
