import React, { useState, useEffect } from 'react';

import { Box, ClickAwayListener } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

import presetColors from './defaultPresetColors';

const useStyles = makeStyles({ name: 'CustomColorPicker' })((theme, { align }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: align,
    alignItems: 'center',
  },
  colorCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    justifySelf: 'center',
    cursor: 'pointer',
    transition: theme.transitions.create('transform'),
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: '2000',
    top: '-10px',
    left: '25px',
  },
}));

const transformColor = (color) => `${color.slice(0, 1) === '#' ? '' : '#'}${color}`;

const CustomColorPicker = ({
  align = 'center',
  color,
  onChange,
  showColorPicker,
  toggleShowColorPicker,
  className = '',
  style: userStyles = {},
  defaultPresetColors = presetColors,
  sx,
  slotProps = {},
}) => {
  const { classes, cx } = useStyles({ align });

  const { popover, colorCircle } = slotProps;

  // keep internal color for performance reasons if outside state update is slow
  const [internalColor, setInternalColor] = useState(transformColor(color));
  const handleInternalColorChange = (internalC) => setInternalColor(internalC.hex);
  useEffect(() => setInternalColor(transformColor(color)), [color]);

  return (
    <Box className={cx(classes.container, className)} style={userStyles} sx={sx}>
      <Box
        {...colorCircle}
        component="span"
        className={classes.colorCircle}
        style={{ backgroundColor: internalColor }}
        onClick={toggleShowColorPicker}
      />
      {showColorPicker && (
        <ClickAwayListener onClickAway={toggleShowColorPicker}>
          <Box {...popover} className={classes.popover}>
            <SketchPicker
              color={internalColor}
              onChangeComplete={onChange}
              onChange={handleInternalColorChange}
              disableAlpha={true}
              presetColors={defaultPresetColors}
            />
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

CustomColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showColorPicker: PropTypes.bool,
  toggleShowColorPicker: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  defaultPresetColors: PropTypes.arrayOf(PropTypes.string),
};

export default CustomColorPicker;
